<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-data-table
        :headers="headers"
        :loading="loading"
        show-expand
        :expanded.sync="expanded"
        :options.sync="options"
        :server-items-length="total"
        single-expand
        :items="webhooks"
        item-key="uuid"
        :footer-props="footerProps"
        class="elevation-1"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ prettyDate(item.createdAt) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="py-3" :colspan="headers.length">
            <v-row class="my-1">
              <v-col class="d-flex flex-column">
                <label for="">Event:</label>
                <span>{{ item.event }}</span>
              </v-col>
              <v-col class="d-flex flex-column">
                <label for="">Created At:</label>
                <span>{{ prettyDate(item.createdAt) }}</span>
              </v-col>
            </v-row>
            <v-divider class="my-1"></v-divider>
            <v-row>
              <v-col class="d-flex flex-column">
                <label for="">Time stamp:</label>
                <span>{{ prettyDate(item.timestamp) }}</span>
              </v-col>
              <v-col class="d-flex flex-column">
                <label for="">Updated At:</label>
                <span>{{ prettyDate(item.updatedAt) }}</span>
              </v-col>
            </v-row>
            <v-divider class="my-1"></v-divider>
            <div
              class="
                text-center
                font-weight-bold
                text-decoration-underline
                mb-2
              "
            >
              Headers
            </div>
            <v-row no-gutters>
              <template v-if="item.headers">
                <v-col
                  cols="12"
                  sm="3"
                  class="pa-1"
                  v-for="(value, key, i) in item.headers"
                  :key="i"
                >
                  <div class="d-flex flex-column">
                    <label class="font-weight-bold">{{ key }}:</label>
                    <span>{{ value }}</span>
                  </div>
                </v-col>
              </template>
            </v-row>
            <v-divider class="my-1"></v-divider>
            <div
              class="
                text-center
                font-weight-bold
                text-decoration-underline
                mb-2
              "
            >
              Payload
            </div>
            <v-row>
              <template v-if="item.payload">
                <v-col
                  cols="12"
                  :sm="key == 'object' ? 8 : 3"
                  class="pa-1"
                  v-for="(value, key, i) in item.payload"
                  :key="i"
                >
                  <template v-if="key == 'object'">
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="3"
                        class="pa-1"
                        v-for="(v, k, ind) in value"
                        :key="ind"
                      >
                        <template
                          v-if="
                            k == 'callee' ||
                            k == 'caller' ||
                            k == 'forwarded_by' ||
                            k == 'owner'
                          "
                        >
                          <label class="font-weight-bold">{{ k }}:</label>
                          <div class="d-flex flex-column">
                            <div v-for="(val, ke, index) in v" :key="index">
                              <div class="d-flex flex-column">
                                <label class="font-weight-bold"
                                  >{{ ke }}:</label
                                >
                                <span>{{ val }}</span>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template
                          v-else-if="k == 'call_logs' || k == 'recordings'"
                        >
                          <label class="font-weight-bold">{{ k }}:</label>
                          <div class="d-flex flex-column">
                            <div v-for="(val, ke, index) in v" :key="index">
                              <div class="d-flex">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="4"
                                    v-for="(valu, kk, ii) in val"
                                    :key="ii"
                                  >
                                    <div class="d-flex flex-column">
                                      <label class="font-weight-bold"
                                        >{{ kk }}:</label
                                      >
                                      <span>{{ valu }}</span>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                              <v-divider></v-divider>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex flex-column">
                            <label class="font-weight-bold">{{ k }}:</label>
                            <span>{{ v }}</span>
                          </div>
                        </template>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <div class="d-flex flex-column">
                      <label class="font-weight-bold">{{ key }}:</label>
                      <span>{{ value }}</span>
                    </div>
                  </template>
                </v-col>
              </template>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { utilMixin } from "@/utils/mixins";
import { notifyError } from "@/components/Notification";
export default {
  mixins: [utilMixin],
  data() {
    return {
      webhooks: [],
      expanded: [],
      total: 0,
      loading: false,
      options: {},
      footerProps: {
        "items-per-page-options": [15, 50, 100, 500, 1000],
      },

      headers: [
        {
          text: "Event",
          align: "start",
          sortable: false,
          value: "event",
        },
        {
          text: "Created At",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getWebH();
      }
    },
  },
  methods: {
    getWebH() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      const range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      getAPI("/zoom/list", { params: range })
        .then((res) => {
          const response = res.data;
          this.total = response.count;
          this.webhooks = response.result;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  mounted() {
    this.getWebH();
  },
};
</script>
<style lang=""></style>
